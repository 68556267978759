import { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Box
} from "@mui/material";
import { getNetAmountByCustomer } from "../../../services/SalesService";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28FFF", "#82ca9d"];

interface Props {
    enterprise: string;
}

const ReportsView: React.FC<Props> = ({ enterprise }) => {
    const [salesData, setSalesData] = useState<NetAmountByCustomer[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [endYear, setEndYear] = useState<number>(2024);
    const [initYear, setInitYear] = useState<number>(2024);
    const [endMonth, setEndMonth] = useState<number>(12);
    const [initMonth, setInitMonth] = useState<number>(1);
    const MONTHS = [
        ["Enero", 1],
        ["Febrero", 2],
        ["Marzo", 3],
        ["Abril", 4],
        ["Mayo", 5],
        ["Junio", 6],
        ["Julio", 7],
        ["Agosto", 8],
        ["Septiembre", 9],
        ["Octubre", 10],
        ["Noviembre", 11],
        ["Diciembre", 12],
    ];

    useEffect(() => {
        if (!enterprise) return;
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getNetAmountByCustomer(initMonth, initYear, endMonth, endYear, enterprise);
                setSalesData(data);
            } catch (err) {
                console.error("Error fetching sales data:", err);
                setError("No se pudieron obtener los datos de ventas.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [enterprise, initMonth, initYear, endMonth, endYear]);

    const totalNetAmount = salesData.reduce((sum, item) => sum + item.totalNetAmount, 0);

    const pieChartData = (() => {
        const threshold = totalNetAmount * 0.01; // 1% threshold
        const filteredData = salesData.filter((item) => item.totalNetAmount >= threshold);
        const othersData = salesData.filter((item) => item.totalNetAmount < threshold);

        const othersTotal = othersData.reduce((sum, item) => sum + item.totalNetAmount, 0);

        if (othersTotal > 0) {
            return [
                ...filteredData.map((item) => ({
                    name: item.customerName,
                    value: item.totalNetAmount,
                })),
                { name: "OTROS", value: othersTotal },
            ];
        }

        return filteredData.map((item) => ({
            name: item.customerName,
            value: item.totalNetAmount,
        }));
    })();

    return (
        <div className="flex flex-col p-5 gap-5 h-full overflow-auto">
            {/* Título */}
            <h1 className="text-2xl font-bold">Reporte de ventas</h1>

            {/* Selectores de mes y año */}
            <Box className="flex flex-wrap gap-5 mb-5">
                {/* Fecha de inicio */}
                <div className="flex items-center gap-5">
                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel id="select-init-month">Mes de inicio</InputLabel>
                        <Select
                            labelId="select-init-month"
                            value={initMonth ?? ''}
                            onChange={(e) => { setInitMonth(e.target.value as number); }}
                            label="Mes de inicio"
                        >
                            {MONTHS.map((month) => (
                                <MenuItem key={month[1]} value={month[1]}>
                                    {month[0]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Año de inicio"
                        variant="outlined"
                        type="number"
                        value={initYear}
                        onChange={(event) => setInitYear(parseInt(event.target.value))}
                        InputProps={{ inputProps: { min: 1900, max: 2100 } }}
                        fullWidth
                    />
                </div>

                {/* Fecha de término */}
                <div className="flex items-center gap-5">
                    <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                        <InputLabel id="select-end-month">Mes de término</InputLabel>
                        <Select
                            labelId="select-end-month"
                            value={endMonth ?? ''}
                            onChange={(e) => { setEndMonth(e.target.value as number); }}
                            label="Mes de término"
                        >
                            {MONTHS.map((month) => (
                                <MenuItem key={month[1]} value={month[1]}>
                                    {month[0]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Año de término"
                        variant="outlined"
                        type="number"
                        value={endYear}
                        onChange={(event) => setEndYear(parseInt(event.target.value))}
                        InputProps={{ inputProps: { min: 1900, max: 2100 } }}
                        fullWidth
                    />
                </div>
            </Box>

            {/* Contenido del reporte */}
            {loading && <p>Cargando datos...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {!loading && !error && (
                <div className="flex gap-5">
                    {/* Gráfico de torta */}
                    <div className="flex-1">
                        <PieChart width={900} height={500}>
                            <Pie
                                data={pieChartData}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={150}
                                fill="#8884d8"
                                label={(entry) => `${((entry.value / totalNetAmount) * 100).toFixed(2)}%`}
                            >
                                {pieChartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value: number) => `$${value.toLocaleString()}`} />
                            <Legend layout="vertical" align="right" verticalAlign="middle" />
                        </PieChart>
                    </div>

                    {/* Tabla de ventas por cliente */}
                    <div className="flex-1 overflow-auto">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Cliente</TableCell>
                                    <TableCell>Monto Neto</TableCell>
                                    <TableCell>Porcentaje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {salesData.map((item) => (
                                    <TableRow key={item.customerRut}>
                                        <TableCell>{item.customerName}</TableCell>
                                        <TableCell>${item.totalNetAmount.toLocaleString()}</TableCell>
                                        <TableCell>
                                            {((item.totalNetAmount / totalNetAmount) * 100).toFixed(2)}%
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {/* Fila de total */}
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        ${totalNetAmount.toLocaleString()}
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>100%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportsView;
